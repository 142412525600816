import { EnvironmentVariables } from "@/type/api";
import { ref } from "vue";

const defaultData = {
  utilisateurConnecte: undefined,
  helpMenu: [],
  links: [],
  "tb-url": "",
} as EnvironmentVariables;

const data = ref(defaultData);

export function useEnv() {
  const setEnvironnementVariables = (newData: object) => {
    const result = EnvironmentVariables.safeParse(newData);
    if (!result.success) {
      console.error(result.error);
    } else {
      data.value = result.data;
    }
  };

  return {
    setEnvironnementVariables,
    data,
  };
}
