import { useEnv } from "./useEnv";
import { computed } from "vue";

export function useLinks() {
  const { data } = useEnv();

  const links = computed(() => data.value.links);

  return {
    links,
  };
}
