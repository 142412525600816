import { defineCustomElement } from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import Header from "./components/Header.ce.vue";

if (!customElements.get("its-header")) {
  customElements.define("its-header", defineCustomElement(Header));
}

createApp(App).mount("#app");
