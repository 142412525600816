<template>
  <div>
    <sl-dropdown
      placement="bottom-end"
      distance="10"
      skidding="-10"
      :disabled="!user"
      hoist
    >
      <Trigger slot="trigger">
        <ItsAvatarPill
          size="s"
          :imgPath="avatar"
          :initialsContent="initials"
          :pillColor="userState"
          role="presentation"
        />
      </Trigger>
      <div class="py-2 rounded-[--sl-border-radius-medium] bg-white">
        <div class="py-2 px-4 hover:bg-[#F65030] hover:text-white">
          <a :href="`${baseUrl}/utilisateur/deconnect`">Me déconnecter</a>
        </div>
      </div>
    </sl-dropdown>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUser } from "@/composables/useUser";
import { ItsAvatarPill } from "@developpeur/core-lib-front";
import { useBaseUrl } from "@/composables/useBaseUrl";
import Trigger from "./Trigger.vue";

const { baseUrl } = useBaseUrl();
const { user } = useUser();

const userState = computed(() => (user.value ? "success" : "error"));

const initials = computed(() =>
  user.value
    ? `${user.value?.prenom.charAt(0)}${user.value?.nom.charAt(0)}`
    : "",
);

const avatar = computed(() =>
  user.value ? `${baseUrl.value}/${user.value?.avatar}` : "",
);
</script>
