<template>
  <div class="w-full max-w-[560px]" v-if="baseUrl">
    <form :action="actionUrl" method="get" target="_blank">
      <input type="hidden" name="search" :value="searchText" />
      <input type="hidden" name="searchType" :value="searchType" />
      <sl-input
        type="search"
        placeholder="Rechercher"
        clearable
        autocapitalize="false"
        autocorrect="false"
        autocomplete="false"
        enterkeyhint="search"
        inputmode="text"
        aria-label="Champ de recherche"
        v-model="searchText"
        @sl-clear="searchText = ''"
      >
        <PhMagnifyingGlass slot="prefix" role="presentation" />

        <PhX
          slot="clear-icon"
          color="black"
          weight="bold"
          style="width: 0.8rem"
          role="presentation"
        />

        <sl-dropdown
          ref="dropdownMenu"
          slot="suffix"
          distance="-5"
          skidding="5"
          placement="bottom-start"
          hoist
          class="m-0"
        >
          <div
            slot="trigger"
            class="cursor-pointer flex gap-2 items-center bg-[--its-color-neutral-300] py-1 pl-4 pr-2 rounded"
            tabindex="0"
          >
            <span class="text-sm">{{ searchType }}</span>
            <PhCaretDown weight="bold" role="presentation" />
          </div>
          <div
            class="py-4 min-w-48 rounded-[--sl-border-radius-medium] bg-white max-h-[90vh] overflow-auto"
          >
            <div
              class="cursor-pointer py-1 px-4 hover:bg-[--its-color-neutral-300]"
              v-for="searchTypeValue in searchTypeList"
              @click="onSearchTypeClick(searchTypeValue)"
              @keydown.enter="onSearchTypeClick(searchTypeValue)"
              tabindex="0"
            >
              {{ searchTypeValue }}
            </div>
          </div>
        </sl-dropdown>
      </sl-input>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useBaseUrl } from "@/composables/useBaseUrl";
import { PhCaretDown, PhMagnifyingGlass, PhX } from "@phosphor-icons/vue";
import { SlInput, SlDropdown } from "@shoelace-style/shoelace";
import { computed, ref, useTemplateRef } from "vue";

const { baseUrl } = useBaseUrl();

const searchText = ref("");
const searchTypeList = ["Documents", "Messages", "Contacts"];
const searchType = ref(searchTypeList[0]);
const dropdown = useTemplateRef<SlDropdown>("dropdownMenu");

const onSearchTypeClick = (newType: string) => {
  searchType.value = newType;
  dropdown.value?.hide();
};

const actionUrl = computed(() =>
  baseUrl.value == ""
    ? ""
    : new URL("perimetre", baseUrl.value + "/").toString(),
);
</script>
