import { useEnv } from "./useEnv";
import { computed } from "vue";

export function useUser() {
  const { data } = useEnv();

  const user = computed(() => data.value.utilisateurConnecte);

  return {
    user,
  };
}
