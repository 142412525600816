import { useEnv } from "./useEnv";
import { computed } from "vue";

export function useHelpMenu() {
  const { data } = useEnv();

  const helpMenu = computed(() => data.value?.helpMenu);

  return {
    helpMenu,
  };
}
