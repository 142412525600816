<script setup lang="ts">
import { onBeforeMount } from "vue";
onBeforeMount(() => {
  const header = document.querySelector("its-header");
  if (header) {
    /* Mode autonome : define baseUrl as props */
    // header.setAttribute("baseurl", import.meta.env.VITE_PLATEFORME_BASE);

    /* Mode standalone : call setStandaloneConfig method*/
    const config = {
      utilisateurConnecte: {
        id: "1",
        nom: "Huez",
        prenom: "Nicolas",
        avatar: "img\/illustration\/avatar-m-2.png",
      },
      helpMenu: [
        {
          name: "Formation",
          icon: "graduation-cap",
          items: [
            {
              title: "Guide en ligne",
              tooltip:
                "Guide complet du fonctionnement de la plateforme et les r\u00e9ponses \u00e0 vos questions les plus fr\u00e9quentes.",
              url: "https:\/\/interstis.zendesk.com\/hc\/fr\/",
            },
            {
              title: "Acad\u00e9mie interstis",
              tooltip: "",
              url: "https:\/\/interstis-premium.zendesk.com\/hc\/fr",
              afterIcon: "crown",
            },
            {
              title: "Webinaires et formations",
              tooltip: "",
              url: "https:\/\/formations-premium.interstis.fr\/public\/",
              afterIcon: "crown",
            },
          ],
        },
        {
          name: "Support technique",
          icon: "headset",
          items: [
            {
              title: "Poser une question au support",
              tooltip:
                "Sollicitez l\u0027aide de l\u0027\u00e9quipe du support technique de la plateforme en cas de dysfonctionnement, blocage ou incompr\u00e9hension.",
              url: "teambrain",
            },
            {
              title: "\u00c9tat des services",
              tooltip:
                "Affiche l\u0027\u00e9tat actuel de tous les services du syst\u00e8me. Cliquez pour voir les d\u00e9tails sur la disponibilit\u00e9, les performances et les \u00e9ventuelles alertes.",
              url: "https:\/\/status.interstis.fr\/",
            },
          ],
        },
        {
          name: "Evolutions",
          icon: "cube",
          items: [
            {
              title: "Nouveaut\u00e9s de la plateforme",
              tooltip:
                "D\u00e9couvrez les derni\u00e8res annonces et \u00e9volutions de votre plateforme.",
              url: "https:\/\/interstis.zendesk.com\/hc\/fr\/categories\/360003361157-Evolutions-am%C3%A9liorations",
            },
            {
              title: "Demande d\u0027\u00e9volution",
              tooltip:
                "Participez \u00e0 la construction de la plateforme en proposant des \u00e9volutions et en votant pour celles des autres.",
              url: "https:\/\/interstis-feedback-eqcb6hsq.featureupvote.com\/",
            },
          ],
        },
      ],
      links: [
        {
          id: "20",
          url: "https:\/\/interstis.atlassian.net\/jira",
          image: "icon-outil-default.png",
          titre: "Jira",
        },
        {
          id: "21",
          url: "https:\/\/interstis.ilucca.net\/home",
          image: "icon-outil-default.png",
          titre: "Lucca",
        },
        {
          id: "19",
          url: "https:\/\/gitlab.tech.interstis.fr\/developpeur\/",
          image: "icon-outil-default.png",
          titre:
            "Gitlab avec un titre tr\u00e8s long, pour pouvoir tester comme il faut le positionnement",
        },
      ],
      "tb-url":
        "https:\/\/teambrain.app\/antilop\/app\/teambrain\/externalApp\/front\/extAccess\/dist\/tb-external-v1.js?v=1.0\u0026s=eE9uWGdBQWcyYVlzTy9ka2FDZVFKdz09\u0026c=VFQrMlhJbHU1V1o0SndqVWc1OVpXTTRYMk13K3pzVGZwL253bUJaVzlLMTFHUXBYWmdLT2pZdWQrNENIZGFQSw==\u0026app=SW1LaUM1cVk5RWxuZEZ0Q21QRFZ1Zz09\u0026t=Qk82L20rZFVFY1oxajhZa1VuWmhzQT09\u0026key=SkV0bFE1bGNZT2VQQVZNQ2wxY2hyK3U1aDhmaW90UDVaazZGTmJaUkg4VWRqTFNnWVpscFFUVFVCdFA1MktYK0ZsRlBkdndEZjl2WFFmQjVCYWRoZEE9PQ==",
    };

    //@ts-ignore
    header._instance.exposed.setStandaloneConfig(
      config,
      import.meta.env.VITE_PLATEFORME_BASE,
    );
  }
});
</script>

<template></template>
