import { z } from "zod";

export const UserConnecte = z.object({
  id: z.coerce.number(),
  nom: z.string(),
  prenom: z.string(),
  avatar: z.string().optional(),
});
export type UserConnecte = z.infer<typeof UserConnecte>;

export const HelpItem = z.object({
  title: z.string(),
  tooltip: z.string(),
  url: z.string(),
  afterIcon: z.string().optional(),
});
export type HelpItem = z.infer<typeof HelpItem>;

export const HelpCategory = z.object({
  name: z.string(),
  icon: z.string(),
  items: z.preprocess(
    (data) => (Array.isArray(data) ? data : Array.of(data)),
    z.array(HelpItem),
  ),
});
export type HelpCategory = z.infer<typeof HelpCategory>;

export const Links = z.object({
  id: z.coerce.number(),
  url: z.string(),
  image: z.string(),
  titre: z.string().nullable(),
});
export type Links = z.infer<typeof Links>;

export const EnvironmentVariables = z.object({
  utilisateurConnecte: UserConnecte.optional(),
  helpMenu: z.array(HelpCategory),
  links: z.array(Links),
  "tb-url": z.string(),
});
export type EnvironmentVariables = z.infer<typeof EnvironmentVariables>;
