<template>
  <div class="ps-16 py-1">
    <a
      :href="url"
      target="_blank"
      class="flex gap-4 items-center hover:underline cursor-pointer"
      :class="classObject"
      :title="item.tooltip"
      v-on="clickEvent"
    >
      <span>{{ item.title }}</span>
      <component :is="icon" weight="fill" size="1.2em" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { HelpItem } from "@/type/api";
import { helpMenuFunctionKey } from "@/type/menu";
import { PhCrown } from "@phosphor-icons/vue";
import { computed, inject } from "vue";

const helpMenuFunction = inject(helpMenuFunctionKey, {});

const props = defineProps<{
  item: HelpItem;
}>();

const clickEvent = computed(() => {
  if (props.item.url in helpMenuFunction) {
    return {
      click: helpMenuFunction[props.item.url],
    };
  }
  return {};
});

const url = computed(() =>
  clickEvent.value.click ? undefined : props.item.url,
);

const icon = computed(() => {
  switch (props.item.afterIcon) {
    case "crown":
      return PhCrown;
    default:
      return null;
  }
});

const classObject = computed(() => ({
  "text-[--its-color-info-300]": icon.value,
}));
</script>
