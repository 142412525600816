<template>
  <div
    class="cursor-pointer hover:bg-[--its-color-info-0] w-8 h-8 flex justify-center items-center rounded"
    tabindex="0"
  >
    <slot> </slot>
  </div>
</template>

<script setup lang="ts"></script>
